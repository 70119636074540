// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#000030, #002d18, #5af1bb, #21c0a0, #384386, #aa304b, #ff7f7f, #d96652, #35373e, #000030",
  firstName: "Chair",
  middleName: "Forces",
  lastName: "LLC",
  message: " As BrrrrrRRRRRrrrrrrRRRRRrrrrrrtT my last email. ",
    icons: [
    ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/chairforces.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "Products that give you the warm and fuzzies.",
  imageLink: require("../editable-stuff/chair-forces-zoom.gif"),
  imageSize: 375,
  secondImage: require("../assets/img/humming-turtle.jpg"),
};

const whitepaper = {
  show: true,
  link: require("../assets/white-paper.pdf"),
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: false,
  heading: "News",
  gitHubUsername: "chairforces", //i.e."johnDoe12Gh"
  reposLength: 4,
  specificRepos: [],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "no-Publications",
  message:
    "",
  images: [
    { 
      img: require("../assets/img/quenched.png"), 
      label: "", 
      paragraph: "" 
    },
    // { 
    //   img: require("../assets/img/quantum.png"), 
    //   label: "", 
    //   paragraph: "" 
    // },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Features",
  hardSkills: [
    { name: "Comfort", value: 100 },
    { name: "Luxury", value: 80 },
    { name: "Style", value: 90 },
    { name: "Hell Yeah Brother", value: 100 },
    { name: "Made in America", value: 99 },
    { name: "Veteran Owned and Operated", value: 100 },
    { name: "Real Men of Genius", value: 85 },
    { name: "Customization", value: 70 },
  ],  
	  softSkills: [
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Contact",
  message:
    "Please reach out to Bruce and Dan at their linkedins, or ",
  email: "force chairs at mail of the G dot com",
};


const experiences = {
  show: true,
  heading: "Examples in action",
  message:
    "",
  data: [
  {
    role: 'This is fine.',
    companylogo: require('../assets/img/fine-small.png'),
    date: '',
  },
  {
    role: 'Models come in a left and a right hand.  See the lower part for the outside half of the hand.',
    companylogo: require('../assets/img/yoda-small.png'),
    date: '',
  },
    {
      role: 'Nothing quite like metal keycaps.',
      companylogo: require('../assets/img/main-event-small.png'),
      date: '',
    },
  {
    role: 'You\'ll feel you\'re floating through the air at work.',
    companylogo: require('../assets/img/floating-small.png'),
    date: '',
    // thesis: 'Thesis: Non-equilibrium physics: from spin glasses to machine and neural learning'
  },
 {
    role: 'Wrist rests, smooth like a calm bay sunrise.',
    companylogo: require('../assets/img/water-small.png'),
    date: '',
    // thesis: 'Thesis: Associative pattern recognition through macro-molecular self-assembly'
  },
  {
    role: 'Destroy all enemies, foreign and domestic, thanks to our ergonomic office enhancements.',
    companylogo: require('../assets/img/brrrt-small.png'),
    date: '',
    // thesis: 'Thesis: A Holographic c-Theorem for Schrödinger Spacetimes'
  },
  ]
}

const portfolio = {
  show: true,
  heading: "A closer look",
  note: "",
   list: [
    {
    category: "Mobile App",
    title: "Obscenely ergonomic.",
    url: "https://arxiv.org/abs/2401.06300",
    notes: "Here we see the ergonomic designs in action.  You've got your 60% keyboard, wrist rests, and a flat surface for them.  You also have your trusty Mousepad 9,000 (you're over 9,000).",
    thumbnail:require("../editable-stuff/birds.png"), 
   },
   {
    category: "Mobile App",
    title: "Spin fidget spinner spin fidget spinner spin fidget spinner spin.",
    url: "https://arxiv.org/abs/2206.08933",
    present:require("../editable-stuff/link.png"),
    notes: "Here we have our left-hand wrist rest.  You can pick it up and move it, and the magnet moves with your wrist.",
    thumbnail:require("../editable-stuff/link.png"), 
   },

   {
    category: "Machine Learning",
    title: "This is you on Chair Forces.",
    url:"https://arxiv.org/abs/2207.02346",
    present:require("../editable-stuff/hand.png"),
    notes: "Hell yeah brother.  Soaring through the open sky.  Your boss wants your setup, computers want to be your setup.",
    thumbnail:require("../editable-stuff/hand.png"), 
    // repo: "https://github.com/xunxdd/ILML---Google-Deep-Dream",
   },
   {
    category: "Machine Learning", 
    title: "!!!",
    url:"https://arxiv.org/abs/2001.03623",
    notes: "So much efficiency you can't even see the programs loading.",
    thumbnail:require("../editable-stuff/uniform.png"), 
    repo: "https://github.com/smarzen/Statistical-Physics", 
    // videoUrl:""
   }
  ]

};

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences, portfolio, whitepaper };
